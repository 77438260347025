<template>
  <div class="autocomplete">
    <div class="autocomplete-container">
      <input
        ref="input"
        type="text"
        :value="keyword"
        :placeholder="placeholder"
        @input="onInput($event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
      />
      <img
        v-if="showAllOptions"
        :src="dropdownIcon"
        alt=""
        class="dropdown-icon"
      />
    </div>

    <div v-if="showAutocompleteDropdown" class="autocomplete-dropdown">
      <ul class="autocomplete-options">
        <li
          v-for="item in getOptions()"
          :key="item[valueKey]"
          class="autocomplete-item"
          tabindex="0"
          @mousedown="onSelect(item)"
        >
          <slot name="autocomplete-label" :item="item"></slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dropdownIcon from '@/assets/images/icon_select.svg'

export default {
  name: 'Autocomplete',

  props: {
    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    options: {
      type: [Array, Promise],
      default: () => [],
    },

    valueKey: {
      type: String,
      default: 'id',
    },

    showAllOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: this.value,
      searchMinLength: 3,
      isPromisePending: false,
      displayDropdown: false,
      filteredAsyncOptions: [],
      dropdownIcon,
    }
  },
  computed: {
    showAutocompleteDropdown() {
      return this.showAllOptions
        ? this.displayDropdown
        : this.filteredAsyncOptions.length
    },
  },

  watch: {
    value(value) {
      this.keyword = value
    },

    options: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.isPromise(this.options)) {
          this.isPromisePending = true

          await this.options.then((options) => {
            this.filteredAsyncOptions = options
            this.isPromisePending = false
          })
        }
      },
    },
  },
  methods: {
    getOptions() {
      if (this.isPromise(this.options)) {
        return this.filteredAsyncOptions
      }

      return this.options
    },

    isPromise(promise) {
      return !!promise && typeof promise.then === 'function'
    },

    onInput(term) {
      this.keyword = term
      this.emitInput()
      if (term.length >= this.searchMinLength) {
        this.$emit('on:search', term)
      } else {
        this.resetOptions()
      }
    },

    resetOptions() {
      this.filteredAsyncOptions = []
    },

    onSelect(item) {
      this.$emit('select', item)
      this.keyword = ''
      this.emitInput()
      this.resetOptions()
    },

    emitInput() {
      this.$emit('input', this.keyword)
    },

    resetKeyword() {
      this.keyword = ''
      this.emitInput()
    },
    onFocus() {
      if (!this.showAllOptions) {
        return
      }
      this.displayDropdown = true
    },
    onBlur() {
      if (!this.showAllOptions) {
        return
      }
      this.displayDropdown = false
    },
  },
}
</script>

<style scoped>
.autocomplete {
  position: relative;
  margin-top: 10px;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-dropdown {
  position: absolute;
  display: inline-block;
  width: 100%;
  background-color: var(--color-white);
  z-index: 9;
}

.autocomplete-options {
  padding: 0px;
  margin: 0 auto;
  width: 92%;
  border: 1px solid var(--color-grey-light);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: white;
  border-top: none;
}

.autocomplete-options .autocomplete-item {
  border-bottom: 1px solid var(--color-grey-light);
  padding: 15px;
  cursor: pointer;
}
.autocomplete-options .autocomplete-item:last-child {
  border-bottom: none;
}
.dropdown-icon {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 20px;
  margin-top: 2px;
}
</style>
